import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Span } from "@quarkly/widgets";
import QuarklycommunityKitMarquee from "./QuarklycommunityKitMarquee";
const defaultProps = {
	"background": "--color-primary",
	"lg-display": "none"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "70px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "space-around",
			"width": "100%",
			"color": "--light",
			"text-transform": "uppercase",
			"font": "--lead"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-transform": "uppercase",
			"letter-spacing": "1.5px",
			"font": "--lead",
			"children": "ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0955118688"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-transform": "uppercase",
			"letter-spacing": "1.5px",
			"font": "--lead",
			"children": <Span
				font="--lead"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0955118688
				</Span>
			</Span>
		}
	}
};

const Marque = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitMarquee {...rest}>
		<Override slot="Container" width="100%" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		{children}
	</QuarklycommunityKitMarquee>;
};

Object.assign(Marque, { ...QuarklycommunityKitMarquee,
	defaultProps,
	overrides
});
export default Marque;